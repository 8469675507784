import React from 'react';
import classes from './PaymentMethods.module.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CreditCardIcon } from '../../../assets/credit-card.svg';
import { ReactComponent as ECheckIcon } from '../../../assets/e-check.svg';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/types/appState';
import Header from '../../ui/layout/Header/Header';
import GooglePayButton from '../GooglePay/GooglePayButton';

export type Props = {
  showBackButton: boolean;
  back: () => void;
  chooseCC: () => void;
  chooseACH: () => void;
};

const PaymentMethods = (props: Props) => {
  const [t] = useTranslation();

  const isAchEnabled = useSelector<AppState, boolean>(
    (state) => state.merchant.achEnabled
  );

  const isCCEnabled = useSelector<AppState, boolean>(
    (state) => state.merchant.ccEnabled
  );

  const isGooglePayEnabled = useSelector<AppState, boolean>(
    (state) => state.merchant.googlePayEnabled
  );

  return (
    <>
      <Header
        title={t('payment.payment-method')}
        subTitle={t('payment.choose-payment-type')}
        showBackButton={props.showBackButton}
        back={props.back}
      />
      <div>
        {isCCEnabled && (
          <button
            id="chooseCreditCardPaymentMethodBtn"
            className={`${classes.PaymentMethodButton} ButtonOutlined`}
            onClick={props.chooseCC}
          >
            <CreditCardIcon />
            {t('payment.credit-card')}
          </button>
        )}
        {isAchEnabled && (
          <button
            id="chooseCheckPaymentMethodBtn"
            className={`${classes.PaymentMethodButton} ButtonOutlined`}
            onClick={props.chooseACH}
          >
            <ECheckIcon />
            {t('payment.e-check')}
          </button>
        )}
        {isGooglePayEnabled && <GooglePayButton />}
      </div>
    </>
  );
};

export default PaymentMethods;
