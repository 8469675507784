import React, { useEffect } from 'react';
import { AppState } from '../../../store/types/appState';
import { useDispatch, useSelector } from 'react-redux';
import useLoadStripe from '../../../hooks/useLoadStripe';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { createCheckoutSession } from '../../../store/actions/stripe';
import Loader from '../../ui/Loader/Loader';

const StripeExternalForm = () => {
    const dispatch = useDispatch();
    
    const clientSecret = useSelector<AppState, string>(
        (state) => state.stripe.checkoutSessionClientSecret
    );

    const isLoading = useSelector<AppState, boolean>(
        (state) => state.stripe.loading
    );

    const checkoutSession = useSelector<AppState, string>(
        (state) => state.stripe.checkoutSessionClientSecret
    )

    useEffect(() => {
        if(!checkoutSession)
            dispatch(createCheckoutSession())
    }, [dispatch, checkoutSession])

    const { stripePromise } = useLoadStripe();

    return (
        <>
            {(isLoading) && <Loader />}
            {!isLoading && (
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{ clientSecret: clientSecret }}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            )
            }
        </>
    );
};

export default StripeExternalForm;