import * as actionTypes from './actionTypes';
import { ThunkDispatch } from 'redux-thunk';
import {
  AppState,
  NMIPaymentMethodNewCC,
  NMIPaymentMethodNewECheck,
  PayForMobileWalletTransactionData,
  PaymentMethodType,
  TransactionStatus,
} from '../types/appState';
import { AnyAction } from 'redux';
import {
  transactionCompleted,
  setError,
  transactionFail,
} from '.';
import ApiMethods from '../../constants/urls';
import axios from '../../utils/axios-tezpay';
import {
  ApiActionStatus,
  ApiTransactionStatus,
  CreditCardType,
  TenderType,
} from '../../types/api/apiEnums';
import {
  addPaymentMethodSuccess,
  addPaymentMethodDecline,
  addPaymentMethodFail,
  deletePaymentMethodDecline,
  deletePaymentMethodFail,
  deletePaymentMethodSuccess,
} from './paymentMethods';
import { transactionStatus } from '../../utils/utils';
import { setDigitalWalletCreditCard } from './transaction';

export const setNMICCPaymentToken = (
  paymentToken: string,
  ccType: CreditCardType
) => ({
  type: actionTypes.SET_NMI_CC_PAYMENT_TOKEN as typeof actionTypes.SET_NMI_CC_PAYMENT_TOKEN,
  paymentToken,
  ccType,
});

export const setNMICCPaymentData = (
  zipCode: string,
  address: string,
  firstName: string,
  lastName: string
) => {
  return {
    type: actionTypes.SET_NMI_CC_PAYMENT_DATA as typeof actionTypes.SET_NMI_CC_PAYMENT_DATA,
    zipCode,
    address,
    firstName,
    lastName,
  };
};

export const setNMIECheckPaymentToken = (
  paymentToken: string,
  name: string
) => ({
  type: actionTypes.SET_NMI_ECHECK_PAYMENT_TOKEN as typeof actionTypes.SET_NMI_ECHECK_PAYMENT_TOKEN,
  paymentToken,
  name,
});

export const createNMIAutoPayment = () => {
  return (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>,
    getState: () => AppState
  ) => {
    dispatch(createNMIAutoPaymentStart());

    const state = getState();
    const nmi = state.nmi;

    const urlAddress = state.transaction.savePaymentMethod
      ? ApiMethods.Portal.AutoPayment.CreateAutoPayment
      : ApiMethods.Portal.AutoPayment.OneTimePayment;

    let requestData = null;
    switch (nmi.paymentMethod?.type) {
      case PaymentMethodType.NewCC:
        requestData = {
          paymentToken: nmi.paymentMethod.paymentToken,
          zipCode: nmi.paymentMethod.zipCode,
          address: nmi.paymentMethod.address,
          firstName: nmi.paymentMethod.firstName,
          lastName: nmi.paymentMethod.lastName,
          type: state.transaction.tenderType,
        };
        break;
      case PaymentMethodType.NewECheck:
        requestData = {
          paymentToken: nmi.paymentMethod.paymentToken,
          lastName: nmi.paymentMethod.name,
          type: state.transaction.tenderType,
        };
        break;
      default:
        console.error('unknown payment method');
        dispatch(setError('error.unkown-error'));
        break;
    }

    if (requestData?.paymentToken === '') {
      console.error('paymentToken is null');
      dispatch(setError('error.unkown-error'));
      return;
    }

    axios
      .post(urlAddress, requestData)
      .then((response) => {
        const status = transactionStatus(response.data.data.transactionStatus as ApiTransactionStatus);
        if (status !== TransactionStatus.Unknown)
          dispatch(transactionCompleted(status))
        else
          dispatch(setError('error.unknown-error'));
      })
      .catch((error) => {
        dispatch(transactionFail());
        if (error.response && error.response.status === 401)
          dispatch(setError('error.unauthorized', false));
        else dispatch(setError('error.api-error'));
      })
      .finally(() =>
        dispatch(payForNMITransactionEnd()));
  };
};

export const payForNMIMobileWalletTransaction = (
  PaymentToken: string,
  CardType: CreditCardType,
  Last4Digits: string | undefined,
) => {
  return (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>,
  ) => {

    dispatch(setDigitalWalletCreditCard(Last4Digits ?? '', CardType))

    let url = ApiMethods.Portal.NMITransaction.GooglePay;
    let data: PayForMobileWalletTransactionData = {
      PaymentToken: PaymentToken
    };

    axios
      .post(url, data)
      .then((response) => {
        const status = transactionStatus(response.data.data.transactionStatus as ApiTransactionStatus);
        if (status !== TransactionStatus.Unknown)
          dispatch(transactionCompleted(status))
        else
          dispatch(setError('error.unknown-error'));
      })
      .catch((error) => {
        dispatch(transactionFail());
        if (error.response && error.response.status === 401)
          dispatch(setError('error.unauthorized', false));
        else dispatch(setError('error.api-error'));
      })
      .finally(() => {

      });
  };
};

export const payForNMITransaction = () => {
  return (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>,
    getState: () => AppState
  ) => {
    dispatch(payForNMITransactionStart());

    const state = getState();
    const nmi = state.nmi;

    let url = '';
    let data = null;

    switch (nmi.paymentMethod?.type) {
      case PaymentMethodType.NewCC:
        if (nmi.paymentMethod.paymentToken !== '') {
          url = state.transaction.savePaymentMethod
            ? ApiMethods.Portal.NMITransaction.PaymentMethod
            : ApiMethods.Portal.NMITransaction.Base;
          data = {
            paymentToken: nmi.paymentMethod.paymentToken,
            zipCode: nmi.paymentMethod.zipCode,
            address: nmi.paymentMethod.address,
            firstName: nmi.paymentMethod.firstName,
            lastName: nmi.paymentMethod.lastName,
            type: state.transaction.tenderType,
          };
        } else {
          console.error('paymentToken is null');
          dispatch(setError('error.unknown-error'));
        }
        break;
      case PaymentMethodType.ExistingCC:
        url = ApiMethods.Portal.NMITransaction.CreditCard;
        data = {
          providerCreditCardId: nmi.paymentMethod.creditCardId,
          cvv: nmi.paymentMethod.cvv,
        };
        break;

      case PaymentMethodType.NewECheck:
        if (nmi.paymentMethod.paymentToken !== '') {
          url = state.transaction.savePaymentMethod
            ? ApiMethods.Portal.NMITransaction.PaymentMethod
            : ApiMethods.Portal.NMITransaction.Base;
          data = {
            paymentToken: nmi.paymentMethod.paymentToken,
            type: state.transaction.tenderType,
          };
        } else {
          console.error('paymentToken is null');
          dispatch(setError('error.unkown-error'));
        }
        break;
      case PaymentMethodType.ExistingECheck:
        url = ApiMethods.Portal.NMITransaction.BankAccount;
        data = {
          providerBankAccountId: nmi.paymentMethod.bankAccountId,
        };
        break;

      default:
        console.error('unknown payment method');
        dispatch(setError('error.unknown-error'));
        break;
    }

    axios
      .post(url, data)
      .then((response) => {
        const status = transactionStatus(response.data.data.transactionStatus as ApiTransactionStatus);
        if (status !== TransactionStatus.Unknown)
          dispatch(transactionCompleted(status))
        else
          dispatch(setError('error.unknown-error'));
      })
      .catch((error) => {
        dispatch(transactionFail());
        if (error.response && error.response.status === 401)
          dispatch(setError('error.unauthorized', false));
        else dispatch(setError('error.api-error'));
      })
      .finally(() =>
        dispatch(payForNMITransactionEnd()));

  };
};

export const payForNMITransactionStart = () => {
  return {
    type: actionTypes.PAY_FOR_NMI_TRANSACTION_START as typeof actionTypes.PAY_FOR_NMI_TRANSACTION_START,
  };
};

export const payForNMITransactionEnd = () => {
  return {
    type: actionTypes.PAY_FOR_NMI_TRANSACTION_END as typeof actionTypes.PAY_FOR_NMI_TRANSACTION_END,
  };
};

export const createNMIAutoPaymentStart = () => {
  return {
    type: actionTypes.CREATE_NMI_AUTOPAYMENT_START as typeof actionTypes.CREATE_NMI_AUTOPAYMENT_START,
  };
};

export const createNMIAutoPaymentEnd = () => {
  return {
    type: actionTypes.CREATE_NMI_AUTOPAYMENT_END as typeof actionTypes.CREATE_NMI_AUTOPAYMENT_END,
  };
};

export const setNMITransactionCreditCard = (
  creditCardId: string,
  cvv: string
) => {
  return {
    type: actionTypes.SET_NMI_TRANSACTION_CREDIT_CARD as typeof actionTypes.SET_NMI_TRANSACTION_CREDIT_CARD,
    creditCardId: creditCardId,
    cvv: cvv,
  };
};

export const setNMITransactionECheck = (eCheckId: string) => {
  return {
    type: actionTypes.SET_NMI_TRANSACTION_ECHECK as typeof actionTypes.SET_NMI_TRANSACTION_ECHECK,
    eCheckId: eCheckId,
  };
};

export const addNMIPaymentMethod = () => {
  return (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>,
    getState: () => AppState
  ) => {
    dispatch(addNMIPaymentMethodStart());

    const state = getState();
    const nmi = state.nmi;

    let url;
    let data;

    if (nmi.paymentMethod?.type === PaymentMethodType.NewCC) {
      const newCC: Omit<NMIPaymentMethodNewCC, 'type'> = {
        paymentToken: nmi.paymentMethod.paymentToken,
        zipCode: nmi.paymentMethod.zipCode,
        address: nmi.paymentMethod.address,
        firstName: nmi.paymentMethod.firstName,
        lastName: nmi.paymentMethod.lastName,
        ccType: nmi.paymentMethod.ccType,
      };
      data = newCC;
      url = ApiMethods.Portal.NMIPaymentMethod.NMICreditCard;
    } else if (nmi.paymentMethod?.type === PaymentMethodType.NewECheck) {
      const newECheck: Omit<NMIPaymentMethodNewECheck, 'type'> = {
        paymentToken: nmi.paymentMethod.paymentToken,
        name: nmi.paymentMethod.name,
      };
      data = newECheck;
      url = ApiMethods.Portal.NMIPaymentMethod.NMIeCheck;
    } else if (nmi.paymentMethod?.type === PaymentMethodType.ExistingCC)
      throw new Error('Cannot add nmi payment methods with type ExistingCC');
    else
      throw new Error(
        'Cannot add nmi payment methods with type ExistingECheck'
      );

    if (url !== '') {
      axios
        .post(url, data)
        .then((response) => {
          const status = response.data.data.status as ApiActionStatus;

          switch (status) {
            case ApiActionStatus.Approved:
              dispatch(addPaymentMethodSuccess());
              dispatch(addNMIPaymentMethodSuccess());
              break;
            case ApiActionStatus.Declined:
              dispatch(setError('error.adding-payment-method-failed'));
              dispatch(addPaymentMethodDecline());
              dispatch(addNMIPaymentMethodDecline());
              break;
            default:
              console.error('status is not known');
              dispatch(setError('error.unknown-error'));
              break;
          }
        })
        .catch((error) => {
          dispatch(addPaymentMethodFail());
          dispatch(addNMIPaymentMethodFail());
          if (error.response && error.response.status === 401)
            dispatch(setError('error.unauthorized', false));
          else dispatch(setError('error.api-error'));
        });
    } else {
      console.error('url not set');
      dispatch(setError('error.unknown-error'));
    }
  };
};

export const addNMIPaymentMethodStart = () => {
  return {
    type: actionTypes.ADD_NMI_PAYMENT_METHOD_START as typeof actionTypes.ADD_NMI_PAYMENT_METHOD_START,
  };
};

export const addNMIPaymentMethodSuccess = () => {
  return {
    type: actionTypes.ADD_NMI_PAYMENT_METHOD_SUCCESS as typeof actionTypes.ADD_NMI_PAYMENT_METHOD_SUCCESS,
  };
};

export const addNMIPaymentMethodDecline = () => {
  return {
    type: actionTypes.ADD_NMI_PAYMENT_METHOD_DECLINE as typeof actionTypes.ADD_NMI_PAYMENT_METHOD_DECLINE,
  };
};

export const addNMIPaymentMethodFail = () => {
  return {
    type: actionTypes.ADD_NMI_PAYMENT_METHOD_FAIL as typeof actionTypes.ADD_NMI_PAYMENT_METHOD_FAIL,
  };
};

export const deleteNMIPaymentMethod = (
  paymentMethodId: string,
  tenderType: TenderType
) => {
  return (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>,
    getState: () => AppState
  ) => {
    dispatch(deleteNMIPaymentMethodStart());

    let url;

    switch (tenderType) {
      case TenderType.CreditCard:
        url = ApiMethods.Portal.NMIPaymentMethod.NMICreditCard;
        break;
      case TenderType.ECheck:
        url = ApiMethods.Portal.NMIPaymentMethod.NMIeCheck;
        break;
      default:
        console.error('tenderType is not known');
        dispatch(setError('error.unknown-error'));
        break;
    }

    if (url && url !== '') {
      url += `/${paymentMethodId}`;

      axios
        .delete(url)
        .then((response) => {
          const status = response.data.data.status as ApiActionStatus;
          switch (status) {
            case ApiActionStatus.Approved:
              dispatch(deletePaymentMethodSuccess());
              dispatch(deleteNMIPaymentMethodSuccess());
              break;
            case ApiActionStatus.Declined:
              dispatch(setError('error.deleteing-payment-method-failed'));
              dispatch(deletePaymentMethodDecline());
              dispatch(deleteNMIPaymentMethodDecline());
              break;
            default:
              console.error('status is not known');
              dispatch(setError('error.unknown-error'));
              break;
          }
        })
        .catch((error) => {
          dispatch(deletePaymentMethodFail());
          dispatch(deleteNMIPaymentMethodFail());
          if (error.response && error.response.status === 401)
            dispatch(setError('error.unauthorized', false));
          if (error.response && error.response.data.error.errorCode === 2010)
            dispatch(
              setError('error.pending-pre-auth-credit-card-transaction')
            );
          else dispatch(setError('error.api-error'));
        });
    } else {
      console.error('url not set');
      dispatch(setError('error.unknown-error'));
    }
  };
};

export const deleteNMIPaymentMethodStart = () => {
  return {
    type: actionTypes.DELETE_NMI_PAYMENT_METHOD_START as typeof actionTypes.DELETE_NMI_PAYMENT_METHOD_START,
  };
};

export const deleteNMIPaymentMethodSuccess = () => {
  return {
    type: actionTypes.DELETE_NMI_PAYMENT_METHOD_SUCCESS as typeof actionTypes.DELETE_NMI_PAYMENT_METHOD_SUCCESS,
  };
};

export const deleteNMIPaymentMethodDecline = () => {
  return {
    type: actionTypes.DELETE_NMI_PAYMENT_METHOD_DECLINE as typeof actionTypes.DELETE_NMI_PAYMENT_METHOD_DECLINE,
  };
};

export const deleteNMIPaymentMethodFail = () => {
  return {
    type: actionTypes.DELETE_NMI_PAYMENT_METHOD_FAIL as typeof actionTypes.DELETE_NMI_PAYMENT_METHOD_FAIL,
  };
};

export type Actions =
  | ReturnType<typeof setNMICCPaymentToken>
  | ReturnType<typeof setNMICCPaymentData>
  | ReturnType<typeof setNMIECheckPaymentToken>
  | ReturnType<typeof payForNMITransactionStart>
  | ReturnType<typeof setNMITransactionCreditCard>
  | ReturnType<typeof setNMITransactionECheck>
  | ReturnType<typeof addNMIPaymentMethodStart>
  | ReturnType<typeof addNMIPaymentMethodSuccess>
  | ReturnType<typeof addNMIPaymentMethodDecline>
  | ReturnType<typeof addNMIPaymentMethodFail>
  | ReturnType<typeof deleteNMIPaymentMethodStart>
  | ReturnType<typeof deleteNMIPaymentMethodSuccess>
  | ReturnType<typeof deleteNMIPaymentMethodDecline>
  | ReturnType<typeof deleteNMIPaymentMethodFail>;
