import React from 'react';
import classes from './Header.module.css';
import { ReactComponent as BackArrow } from '../../../../assets/back-arrow.svg';

export type Props = {
  title: string,
  subTitle: string | null,
  showBackButton: boolean,
  back: () => void
};

const Header = (props: Props) => {
  return (
    <header className={classes.Header}>
      {props.showBackButton && (
        <h1>
          <BackArrow id="backArrowImg" className={classes.Back} onClick={props.back} />
        </h1>
      )}

      <div>
        <h1 className={classes.Title}>{props.title}</h1>
        {props.subTitle !== null && (
          <label className={classes.SubTitle}>
            {props.subTitle}
          </label>
        )}

      </div>
    </header>
  );
};

export default Header;
