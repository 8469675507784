import React from 'react';

export type Props = {
  id: string,
  title: string,
  disabled: boolean | undefined,
  onClick: () => void,
  className: string,
  grow: number | undefined
};

const BottomButton = (props: Props) => {
  const grow = props.grow ?? undefined

  return (
    <button
      id={`${props.id}btn`}
      className={props.className}
      onClick={props.onClick}
      disabled={props.disabled}
      style={{ flexGrow: grow }}
    >
      {props.title}
    </button>
  );
};

export default BottomButton;

