import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentDecline from '../../components/Payment/PaymentDeclined/PaymentDeclined';
import PaymentSuccess from '../../components/Payment/PaymentSuccess/PaymentSuccess';
import PaymentLayout from '../../components/ui/layout/PaymentLayout/PaymentLayout';
import {
  AppState,
  TransactionStatus,
  TransactionState,
  MerchantState,
  RedirectStatusState,
} from '../../store/types/appState';
import { auth, getMerchant, restorePostInit } from '../../store/actions';
import { getRedirectData } from '../../store/actions/redirect';
import { RouteParams } from '../../types/route';
import { RouteComponentProps } from 'react-router-dom';
import { authSession } from '../../store/actions/auth';
import PaymentUserTimeout from '../../components/Payment/PaymentUserTimeout/PaymentUserTimeout';
import { getUserTimer } from '../../store/actions/userTimer';

const config = (window as any).config;

const AfterPayment = (props: RouteComponentProps<RouteParams>) => {
  const dispatch = useDispatch();

  const token = props.match.params.token;

  const transactionData = useSelector<AppState, TransactionState>(
    (state) => state.transaction
  );

  const merchant = useSelector<AppState, MerchantState>(
    (state) => state.merchant
  );

  const redirectStatus = useSelector<AppState, RedirectStatusState>(
    (state) => state.redirectStatus
  );

  const isUserTimerExpired = useSelector<AppState, boolean>(
    (state) => state.userTimer.isUserTimerExpired
  );

  const authToken = useSelector<AppState>((state) => state.auth.token);
  const isTokenValid = useSelector<AppState, boolean>(
    (state) => state.auth.isTokenValid
  );

  useEffect(() => {
    if (authToken !== null && isTokenValid === true) {
      dispatch(getUserTimer());
      dispatch(getMerchant());
    }
  }, [authToken, isTokenValid, dispatch]);

  useEffect(() => {
    dispatch(auth(token));
  }, [token, dispatch]);

  useEffect(() => {
    if (authToken !== null) {
      dispatch(authSession());
    }
  }, [authToken, dispatch]);

  const getRedirectDataCallback = useCallback(() => {
    if (!transactionData.loading && !redirectStatus.loading)
      dispatch(getRedirectData());
  }, [transactionData.loading, redirectStatus.loading, dispatch]);

  const [isTransactionStateLoaded, setIsTransactionStateLoaded] = useState(
    false
  );

  const getRedirectDataCallbackRef = useRef<() => void>();

  useEffect(() => {
    getRedirectDataCallbackRef.current = getRedirectDataCallback;
  }, [getRedirectDataCallback]);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (
      authToken !== null &&
      isTokenValid === true &&
      timer === null &&
      !isUserTimerExpired
    ) {
      setTimer(
        setInterval(async () => {
          if (getRedirectDataCallbackRef.current)
            getRedirectDataCallbackRef.current();
        }, config.pullingRedirectDataInterval)
      );
      if (getRedirectDataCallbackRef.current)
        getRedirectDataCallbackRef.current();
    }
    if (isUserTimerExpired && timer !== null) {
      clearInterval(timer);
    }
  }, [
    authToken,
    isTokenValid,
    getRedirectDataCallback,
    timer,
    isUserTimerExpired,
  ]);

  useEffect(() => {
    return () => {
      if (timer !== null) {
        clearInterval(timer);
      }
    };
  }, [timer]);

  useEffect(() => {
    if (
      transactionData.status !== TransactionStatus.Unknown &&
      transactionData.status !== TransactionStatus.Pending
    ) {
      if (timer !== null) {
        clearInterval(timer);
      }
      setIsTransactionStateLoaded(true);
    }
  }, [transactionData.status, timer]);

  const redirectHandler = () => {
    window.location.href = transactionData.redirectUrl;
  };

  const loading =
    transactionData.loading ||
    merchant.loading ||
    redirectStatus.loading ||
    (!isTransactionStateLoaded &&
      !isUserTimerExpired &&
      authToken !== null &&
      isTokenValid);

  const transactionAmount = transactionData.amount;

  return (
    <>
      <PaymentLayout isLoading={loading} showContentOnLoading={false}>
        {transactionData.status === TransactionStatus.Accepted ? (
          <PaymentSuccess
            name={transactionData.name}
            amount={transactionAmount}
            last4Digits={transactionData.last4Digits}
            creditCardType={transactionData.creditCardType}
            ok={redirectHandler}
          />
        ) : transactionData.status === TransactionStatus.Declined ? (
          <PaymentDecline
            amount={transactionAmount}
            last4Digits={transactionData.last4Digits}
            creditCardType={transactionData.creditCardType}
            cancel={redirectHandler}
            tryAgain={() => dispatch(restorePostInit())}
          />
        ) : transactionData.status === TransactionStatus.UserTimeout ||
          isUserTimerExpired ? (
          <PaymentUserTimeout
            amount={transactionAmount}
            last4Digits={transactionData.last4Digits}
            creditCardType={transactionData.creditCardType}
            cancel={redirectHandler}
            tryAgain={() => dispatch(restorePostInit())}
          />
        ) : null}
      </PaymentLayout>
    </>
  );
};

export default AfterPayment;
