import {
  CreditCardType,
  PortalHideControl,
  TenderType,
} from '../../types/api/apiEnums';

export type AppState = {
  auth: AuthState;
  transaction: TransactionState;
  error: ErrorState;
  merchant: MerchantState;
  nmi: NMIState;
  googlePay: GooglePayState;
  paymentMethods: PaymentMethodsState;
  managePaymentMethods: ManagePaymentMethodsState;
  redirect: RedirectState;
  redirectStatus: RedirectStatusState;
  stripe: StripeState;
  userTimer: UserTimerState;
};

export type AuthState = {
  token: string | null;
  isTokenValid: boolean;
};

export type UserTimerState = {
  userTimerExpireDateUtc: string;
  serverCurrentDateUtc: string;
  isUserTimerExpired: boolean;
  userTimerDuration: number;
};

export type CreditCard = {
  id: string;
  last4Digits: string;
  creditCardType: CreditCardType;
};

export type ECheck = {
  id: string;
  last4Digits: string;
  routingNumberLast4Digits: string;
};

export type GooglePay = {
  TransactionStatus: TransactionStatus;
  last4Digits: string;
  CardType: CreditCardType;
};

export type SelectedCreditCard = {
  id: string;
  zipCode: string;
  cvv: string;
};

export type NewCreditCard = {
  creditCardNumber: string;
  expirationMonth: number;
  expirationYear: number;
  zipCode: string;
  cvv: string;
  saveCard: boolean;
};

export type ErrorState = {
  isError: boolean;
  message: string | null;
  clearable: boolean;
};

export type TransactionState = {
  loadingActions: Array<string>;
  loading: boolean;
  amount: number;
  redirectUrl: string;
  status: TransactionStatus;
  last4Digits: string;
  creditCardType: CreditCardType;
  tenderType: TenderType | null;
  name: string;
  routingNumber: string;
  accountNumber: string;
  paymentStep: PaymentStep;
  isSavingPaymentMethodAllowed: boolean;
  savePaymentMethod: boolean;
};

export type ManagePaymentMethodsState = {
  step: ManagePaymentMethodsStep;
  redirectUrl: string;
  loading: boolean;
};

export enum ManagePaymentMethodsStep {
  ChooseMethod = 1,
  AddCC = 2,
  AddECheck = 3,
  DeleteCC = 4,
  DeleteECheck = 5,
}

export enum VerificationType {
  None = 0,
  Cvv = 1,
  ZipCode = 2,
  //Bit flag Cvv & ZipCode
}

export enum PaymentStep {
  None = 0,
  ChooseMethod = 1,
  ChooseCCMethod = 6,
  ChooseACHMethod = 7,

  Confirm = 2,
  AddPayCard = 3,
  DeleteCC = 4,
  DeleteECheck = 5,

  ExternalForm = 8,
}

export enum TransactionStatus {
  Unknown = 0,
  Pending = 1,
  Accepted = 2,
  Declined = 3,
  UserTimeout = 4,
}

export enum PaymentMethod {
  CC = 1,
  ACH = 2,
}

export type MerchantState = {
  loading: boolean;
  merchantId: string;
  providerType: ProviderType;
  configuration: MerchantConfiguration;
  verificationType: VerificationType;
  ccEnabled: boolean;
  achEnabled: boolean;
  googlePayEnabled: boolean;
  applePayEnabled: boolean;
  showBackButton: boolean;
  availablePaymentMethodTypeCount: number;
};

export enum ProviderType {
  Unknown = 0,
  NMI = 7,
  // eslint-disable-next-line @typescript-eslint/camelcase
  ProPayCNP_NMI = 9,
  /**
   * @deprecated unused enum - StripeMexico is deleted
   */
  StripeMexico = 10,
  /**
   * @deprecated unused enum - Heartland is deleted
   */
  Heartland = 11,
  // eslint-disable-next-line @typescript-eslint/camelcase
  /**
   * @deprecated unused enum - ProPayCNP_Heartland is deleted
   */
  ProPayCNP_Heartland = 12,
  StripeEurope = 13,
  StripeUS = 14,
}

export type NMIMerchantConfiguration = {
  // eslint-disable-next-line @typescript-eslint/camelcase
  type: ProviderType.NMI | ProviderType.ProPayCNP_NMI;
  currencyCode: string;
  tokenizationKey: string;
};

export type StripeMerchantConfiguration = {
  type: ProviderType.StripeEurope | ProviderType.StripeUS;
  currencyCode: string;
  publishableKey: string;
};

export type MerchantConfiguration =
  | NMIMerchantConfiguration
  | StripeMerchantConfiguration
  | null;

export type PayCard =
  | {
      type: TenderType.CreditCard;
      creditCardType: CreditCardType;
      id: string;
      last4Digits: string;
      name: string;
    }
  | {
      type: TenderType.ECheck;
      id: string;
      last4Digits: string;
      routingNumberLast4Digits: string;
    };

export type SelectedPayCard =
  | {
      type: TenderType.CreditCard;
      creditCardId: string;
      zipCode: string;
      cvv: string;
      last4Digits: string;
      creditCardType: CreditCardType;
    }
  | {
      type: TenderType.ECheck;
      eCheckId: string;
      last4Digits: string;
      routingNumberLast4Digits: string;
    };

export type NMIState = {
  paymentMethod: NMIPaymentMethod | null;
};

export type NMIPaymentMethodNewCC = {
  type: PaymentMethodType.NewCC;
  paymentToken: string;
  zipCode: string;
  address: string;
  firstName: string;
  lastName: string;
  ccType: CreditCardType;
};

export type NMIPaymentMethodNewECheck = {
  type: PaymentMethodType.NewECheck;
  paymentToken: string;
  name: string;
};

export type NMIPaymentMethod =
  | NMIPaymentMethodNewCC
  | {
      type: PaymentMethodType.ExistingCC;
      creditCardId: string;
      cvv: string;
    }
  | NMIPaymentMethodNewECheck
  | {
      type: PaymentMethodType.ExistingECheck;
      bankAccountId: string;
    };

export enum PaymentMethodType {
  NewCC = 1,
  ExistingCC = 2,
  NewECheck = 3,
  ExistingECheck = 4,
}

export type PaymentMethodsState = {
  availableCreditCards: Array<CreditCard> | null;
  availableEChecks: Array<ECheck> | null;
  loading: boolean;
};

export type StripeState = {
  loading: boolean;
  checkoutSessionClientSecret: string;
};

export type PaymentMethodNMIProviderType = {
  last4Digits: string;
  ccType: CreditCardType;
};

export type PaymentMethodProviderType = PaymentMethodNMIProviderType;

export type RedirectState = {
  redirectType: RedirectType;
  hideControls: PortalHideControl | null;
  loading: boolean;
};

export type RedirectStatusState = {
  status: RedirectStatus;
  loading: boolean;
};

export enum RedirectType {
  OneTimePayment = 1,
  ManagePaymentMethods = 2,
  AutoPayment = 3,
}

export type PayForMobileWalletTransactionData = {
  PaymentToken: string;
};

export enum RedirectStatus {
  None = 0,
  DuringPayment = 1,
  Succeeded = 2,
  Failed = 3,
  CallbackFailed = 4,
  BeforePayment = 5,
}

export type GooglePayState = {
  PaymentToken: string;
  CardType: CreditCardType;
  Last4Digits: string | undefined;
};
