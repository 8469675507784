import axios from 'axios';
import { AppState } from '../store/types/appState';
import { Store } from 'redux';
import humps from 'humps';
import { config } from './configs';

const instance = axios.create({
  baseURL: config.apiUrl,
});

let store: Store;

instance.interceptors.request.use(function (config) {
  const token = (store.getState() as AppState).auth.token;
  if (token) config.headers.Authorization = 'Bearer ' + token;
  return config;
});

instance.interceptors.response.use(
  (response) => {
    response.data = humps.camelizeKeys(response.data);
    return response;
  },
  function (error) {
    if (error.response)
      error.response.data = humps.camelizeKeys(error.response.data);
    return Promise.reject(error);
  }
);

export const injectStore = (injectedStore: Store) => {
  store = injectedStore;
};
export default instance;
