import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreditCardType } from '../../../types/api/apiEnums';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import Header from '../../ui/layout/Header/Header';
import BottomButtons from '../../ui/layout/BottomButtons/BottomButtons';
import BottomButton from '../../ui/layout/BottomButtons/BottomButton';
import { useSelector } from 'react-redux';
import {
  AppState,
  RedirectStatus,
  RedirectStatusState,
} from '../../../store/types/appState';

export type Props = {
  name: string;
  creditCardType: CreditCardType;
  last4Digits: string;
  amount: number;
  isPayAvailable: boolean;
  pay: () => void;
  back: () => void;
};

const PaymentConfirmation = (props: Props) => {
  const [t] = useTranslation();

  const redirectStatus = useSelector<AppState, RedirectStatusState>(
    (state) => state.redirectStatus
  );

  const [isPayAvailable, setIsPayAvailable] = useState(
    redirectStatus.status !== RedirectStatus.DuringPayment
  );

  return (
    <>
      <Header
        title={t('payment.confirmation')}
        subTitle={null}
        back={props.back}
        showBackButton={true}
      />
      <PaymentDetails
        name={props.name}
        amount={props.amount}
        last4Digits={props.last4Digits}
        creditCardType={props.creditCardType}
        smallCard={false}
      />
      <BottomButtons>
        <BottomButton
          id="paymentConfirmationSubmit"
          className={`ButtonSecondary`}
          onClick={() => {
            setIsPayAvailable(false);
            props.pay();
          }}
          disabled={!isPayAvailable}
          title={t('payment.submit')}
          grow={undefined}
        />
      </BottomButtons>
    </>
  );
};

export default PaymentConfirmation;
