import React from 'react';
import classes from './Checkbox.module.css';

export type Props = {
  id: string;
  label: string;
  isChecked: boolean,
  onChange: (isChecked: boolean) => void
};

const Checkbox = (props: Props) => {
  return (
    <div className={classes.CheckboxInput}>
      <label className={classes.Checkbox}>
        <input
          id={props.id}
          type="checkbox"
          onChange={(event) => props.onChange(event.target.checked)}
          checked={props.isChecked}
        />
        <span>{props.label}</span>
        <span className={classes.Checkmark}></span>
      </label>
    </div>
  );
};

export default Checkbox;
