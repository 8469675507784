import React from 'react';
import classes from './ErrorModal.module.css';
import { useTranslation } from 'react-i18next';

export type Props = {
  title: string;
  message: string;
  clearable: boolean;
  ok: () => void;
};

const ErrorModal = (props: Props) => {
  const [t] = useTranslation();

  return (
    <>
      <div className={classes.Backdrop} />
      <div className={classes.Modal}>
        <h2 className={classes.ModalTitle}>{props.title}</h2>
        <div className={classes.ModalMessage}>{props.message}</div>
        {props.clearable ? (
          <button
            className={`${classes.OkButton} ButtonAlert`}
            onClick={props.ok}
          >
            {t('ok')}
          </button>
        ) : null}
      </div>
    </>
  );
};

export default ErrorModal;
