import * as actionTypes from '../actions/actionTypes';
import {
  NMIPaymentMethodNewCC,
  NMIPaymentMethodNewECheck,
  NMIState,
  PaymentMethodType,
} from '../types/appState';
import { Actions } from '../actions/nmi';
import { CreditCardType } from '../../types/api/apiEnums';

const initialState: NMIState = {
  paymentMethod: null,
};

const setCCPaymentToken = (
  state: NMIState,
  paymentToken: string,
  ccType: CreditCardType
): NMIState => {
  const paymentMethod: NMIPaymentMethodNewCC = {
    firstName: '',
    lastName: '',
    address: '',
    zipCode: '',
    ...state.paymentMethod,
    type: PaymentMethodType.NewCC,
    ccType: ccType,
    paymentToken,
  };

  return {
    ...state,
    paymentMethod,
  };
};

const setECheckPaymentToken = (
  state: NMIState,
  paymentToken: string,
  name: string
): NMIState => {
  const paymentMethod: NMIPaymentMethodNewECheck = {
    ...state.paymentMethod,
    type: PaymentMethodType.NewECheck,
    paymentToken,
    name,
  };

  return {
    ...state,
    paymentMethod,
  };
};

const setCCPaymentData = (
  state: NMIState,
  zipCode: string,
  address: string,
  firstName: string,
  lastName: string
): NMIState => {
  return {
    ...state,
    paymentMethod: {
      paymentToken: '',
      ccType: CreditCardType.Invalid,
      ...state.paymentMethod,
      type: PaymentMethodType.NewCC,
      zipCode: zipCode,
      address: address,
      firstName: firstName,
      lastName: lastName,
    },
  };
};

const setNMITransactionCreditCard = (
  state: NMIState,
  creditCardId: string,
  cvv: string
): NMIState => {
  return {
    ...state,
    paymentMethod: {
      ...state.paymentMethod,
      type: PaymentMethodType.ExistingCC,
      creditCardId: creditCardId,
      cvv: cvv,
    },
  };
};

const setNMITransactionECheck = (
  state: NMIState,
  eCheckId: string
): NMIState => {
  return {
    ...state,
    paymentMethod: {
      ...state.paymentMethod,
      type: PaymentMethodType.ExistingECheck,
      bankAccountId: eCheckId,
    },
  };
};

const reducer = (state: NMIState = initialState, action: Actions): NMIState => {
  switch (action.type) {
    case actionTypes.SET_NMI_CC_PAYMENT_TOKEN:
      return setCCPaymentToken(state, action.paymentToken, action.ccType);
    case actionTypes.SET_NMI_CC_PAYMENT_DATA:
      return setCCPaymentData(
        state,
        action.zipCode,
        action.address,
        action.firstName,
        action.lastName
      );
    case actionTypes.SET_NMI_ECHECK_PAYMENT_TOKEN:
      return setECheckPaymentToken(state, action.paymentToken, action.name);
    case actionTypes.SET_NMI_TRANSACTION_CREDIT_CARD:
      return setNMITransactionCreditCard(
        state,
        action.creditCardId,
        action.cvv
      );
    case actionTypes.SET_NMI_TRANSACTION_ECHECK:
      return setNMITransactionECheck(
        state,
        action.eCheckId
      );
    default:
      return state;
  }
};

export default reducer;
