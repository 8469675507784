import * as actionTypes from './actionTypes';
import {
  MerchantState,
  ProviderType,
  MerchantConfiguration,
} from '../types/appState';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios from '../../utils/axios-tezpay';
import ApiMethods from '../../constants/urls';
import { setError } from '.';

export const getMerchantStart = () => {
  return {
    type: actionTypes.GET_MERCHANT_START as typeof actionTypes.GET_MERCHANT_START,
  };
};

export const getMerchantSuccess = (
  merchantId: string,
  providerType: ProviderType,
  configuration: MerchantConfiguration,
  ccEnabled: boolean,
  achEnabled: boolean,
  showBackButton: boolean,
  googlePayEnabled: boolean,
  applePayEnabled: boolean
) => {
  return {
    type: actionTypes.GET_MERCHANT_SUCCESS as typeof actionTypes.GET_MERCHANT_SUCCESS,
    merchantId: merchantId,
    providerType: providerType,
    configuration: configuration,
    ccEnabled: ccEnabled,
    achEnabled: achEnabled,
    showBackButton: showBackButton,
    googlePayEnabled: googlePayEnabled,
    applePayEnabled: applePayEnabled,
  };
};

export const getMerchantFail = () => {
  return {
    type: actionTypes.GET_MERCHANT_FAIL as typeof actionTypes.GET_MERCHANT_FAIL,
  };
};

export const getMerchant = () => {
  return (dispatch: ThunkDispatch<MerchantState, {}, AnyAction>) => {
    dispatch(getMerchantStart());
    axios
      .get(ApiMethods.Portal.Merchant)
      .then((response) => {
        const data = response.data.data;
        dispatch(
          getMerchantSuccess(
            data.id,
            data.providerType,
            data.configuration,
            data.uIConfig.cCEnabled,
            data.uIConfig.aCHEnabled,
            data.uIConfig.showBackButton,
            data.uIConfig.googlePayEnabled,
            data.uIConfig.applePayEnabled
          )
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(getMerchantFail());
        if (error.response && error.response.status === 401)
          dispatch(setError('error.unauthorized', false));
        else dispatch(setError('error.api-error', false));
      });
  };
};

export type Actions =
  | ReturnType<typeof getMerchantStart>
  | ReturnType<typeof getMerchantFail>
  | ReturnType<typeof getMerchantSuccess>;
