import * as actionTypes from '../actions/actionTypes';
import {
  TransactionState,
  TransactionStatus,
  PaymentStep,
} from '../types/appState';
import * as transaction from '../actions/transaction';
import * as paymentMethods from '../actions/paymentMethods';
import { CreditCardType, TenderType } from '../../types/api/apiEnums';
import { addLoadingAction, removeLoadingAction } from '../../utils/utils';

const initialState: TransactionState = {
  loadingActions: [],
  loading: false,
  amount: 0,
  status: 0,
  redirectUrl: '',
  last4Digits: '',
  creditCardType: CreditCardType.Invalid,
  tenderType: null,
  paymentStep: PaymentStep.None,
  name: '',
  routingNumber: '',
  accountNumber: '',
  isSavingPaymentMethodAllowed: false,
  savePaymentMethod: false,
};

const GET_TRANSACTION = 'GET_TRANSACTION'
const TRANSACTION = 'TRANSACTION'
const ONE_TIME_PAYMENT = 'ONE_TIME_PAYMENT'

const restorePostInit = (state: TransactionState): TransactionState => {
  return {
    ...state,
    paymentStep: PaymentStep.None,
    tenderType: null,
    status: TransactionStatus.Pending,
  };
};

const updateTransaction = (
  state: TransactionState,
  last4Digits: string,
  creditCardType: CreditCardType,
  paymentStep: PaymentStep
) => {
  return {
    ...state,
    last4Digits: last4Digits,
    creditCardType: creditCardType,
    paymentStep: paymentStep,
  };
};



const getTransactionStart = (state: TransactionState) => {
  return {
    ...state,
    ...addLoadingAction(state.loadingActions, GET_TRANSACTION),
  };
};

const getTransactionSuccess = (
  state: TransactionState,
  amount: number,
  status: TransactionStatus,
  redirectUrl: string,
  last4Digits: string,
  cardType: CreditCardType | null,
  tenderType: TenderType | null
): TransactionState => {
  return {
    ...state,
    ...removeLoadingAction(state.loadingActions, GET_TRANSACTION),
    amount: amount,
    status: status,
    redirectUrl: redirectUrl,
    last4Digits: last4Digits ?? state.last4Digits,
    creditCardType: cardType ?? state.creditCardType,
    tenderType: tenderType ?? state.tenderType,
  };
};

const getTransactionFail = (state: TransactionState) => {
  return {
    ...state,
    ...removeLoadingAction(state.loadingActions, GET_TRANSACTION),
  };
};

const transactionStart = (state: TransactionState) => {
  return {
    ...state,
    ...addLoadingAction(state.loadingActions, TRANSACTION),
  };
};

const setTransactionAutoPayment = (
  state: TransactionState,
  isSavingPaymentMethodAllowed: boolean
): TransactionState => {
  return {
    ...state,
    isSavingPaymentMethodAllowed: isSavingPaymentMethodAllowed,
  };
};

const getOneTimePaymentSuccess = (
  state: TransactionState,
  isSavingPaymentMethodAllowed: boolean
): TransactionState => {
  return {
    ...state,
    ...removeLoadingAction(state.loadingActions, ONE_TIME_PAYMENT),
    isSavingPaymentMethodAllowed: isSavingPaymentMethodAllowed,
  };
};

const getOneTimePaymentFail = (state: TransactionState) => {
  return {
    ...state,
    ...removeLoadingAction(state.loadingActions, ONE_TIME_PAYMENT),
  };
};

const getOneTimePaymentStart = (state: TransactionState) => {
  return {
    ...state,
    ...addLoadingAction(state.loadingActions, ONE_TIME_PAYMENT),
  };
};

const transactionCompleted = (state: TransactionState, transactionStatus: TransactionStatus) => {
  return {
    ...state,
    ...removeLoadingAction(state.loadingActions, TRANSACTION),
    status: transactionStatus,
    last4Digits: state.last4Digits,
    creditCardType: state.creditCardType,
  };
};


const transactionFail = (state: TransactionState) => {
  return {
    ...state,
    ...removeLoadingAction(state.loadingActions, TRANSACTION),
    status: 0,
  };
};

const setPaymentData = (
  state: TransactionState,
  savePaymentMethod: boolean
): TransactionState => {
  return {
    ...state,
    savePaymentMethod: savePaymentMethod,
  };
};

const setTransactionCreditCard = (
  state: TransactionState,
  last4Digits: string,
  creditCardType: CreditCardType
): TransactionState => {
  return {
    ...state,
    paymentStep: PaymentStep.Confirm,
    tenderType: TenderType.CreditCard,
    creditCardType: creditCardType,
    last4Digits: last4Digits,
  };
};

const setTransactionECheck = (
  state: TransactionState,
  last4Digits: string,
  name: string
): TransactionState => {
  return {
    ...state,
    paymentStep: PaymentStep.Confirm,
    tenderType: TenderType.ECheck,
    last4Digits,
    name,
  };
};

const addPayCardStep = (
  state: TransactionState,
  tenderType: TenderType
): TransactionState => {
  return {
    ...state,
    paymentStep: PaymentStep.AddPayCard,
    tenderType: tenderType,
  };
};

const setPaymentStep = (state: TransactionState, paymentStep: PaymentStep) => {
  return {
    ...state,
    paymentStep: paymentStep,
  };
};

const setCardTypeAndLast4Digits = (
  state: TransactionState,
  creditCardType: CreditCardType,
  last4Digits: string,
): TransactionState => {
  return {
    ...state,
    creditCardType: creditCardType,
    last4Digits: last4Digits,
    tenderType: TenderType.GooglePay,
  };
};

const reducer = (
  state: TransactionState = initialState,
  action: transaction.Actions | paymentMethods.Actions
): TransactionState => {
  switch (action.type) {
    case actionTypes.GET_TRANSACTION_START:
      return getTransactionStart(state);
    case actionTypes.GET_TRANSACTION_SUCCESS:
      return getTransactionSuccess(
        state,
        action.amount,
        action.status,
        action.redirectUrl,
        action.last4Digits,
        action.cardType,
        action.tenderType
      );
    case actionTypes.GET_TRANSACTION_FAIL:
      return getTransactionFail(state);
    case actionTypes.TRANSACTION_START:
      return transactionStart(state);
    case actionTypes.TRANSACTION_FAIL:
      return transactionFail(state);
    case actionTypes.TRANSACTION_COMPLETED:
      return transactionCompleted(state, action.transactionStatus);
    case actionTypes.UPDATE_TRANSACTION_INFO:
      return updateTransaction(
        state,
        action.last4Digits,
        action.creditCardType,
        action.paymentStep
      );
    case actionTypes.RESTORE_POST_INIT:
    case actionTypes.DELETE_PAYMENT_METHOD_SUCCESS:
    case actionTypes.DELETE_PAYMENT_METHOD_DECLINE:
    case actionTypes.DELETE_PAYMENT_METHOD_FAIL:
      return restorePostInit(state);
    case actionTypes.SET_TRANSACTION_CREDIT_CARD:
      return setTransactionCreditCard(
        state,
        action.last4Digits,
        action.creditCardType
      );
    case actionTypes.SET_TRANSACTION_ECHECK:
      return setTransactionECheck(state, action.last4Digits, action.name);
    case actionTypes.ADD_CREDIT_CARD_STEP:
      return addPayCardStep(state, TenderType.CreditCard);
    case actionTypes.ADD_ECHECK_STEP:
      return addPayCardStep(state, TenderType.ECheck);
    case actionTypes.SET_PAYMENT_DATA:
      return setPaymentData(state, action.savePaymentMethod);
    case actionTypes.SET_PAYMENT_STEP:
      return setPaymentStep(state, action.paymentStep);
    case actionTypes.SET_TRANSACTION_DIGITAL_WALLET:
      return setCardTypeAndLast4Digits(state, action.creditCardType, action.last4Digits);
    case actionTypes.GET_ONE_TIME_PAYMENT_START:
      return getOneTimePaymentStart(state);
    case actionTypes.GET_ONE_TIME_PAYMENT_FAIL:
      return getOneTimePaymentFail(state);
    case actionTypes.GET_ONE_TIME_PAYMENT_SUCCESS:
      return getOneTimePaymentSuccess(state, action.isSavingPaymentMethodAllowed);
      case actionTypes.SET_TRANSACTION_AUTO_PAYMENT:
        return setTransactionAutoPayment(state, action.isSavingPaymentMethodAllowed);
    default:
      return state;
  }
};

export default reducer;
