import React from 'react';
import classes from './BottomButtons.module.scss';

export type Props = {
  children: (JSX.Element | null)[] | (JSX.Element | null)
  disableAutoMarginTop?: boolean
};

const BottomButtons = (props: Props) => {

  const ignoreAutoMargin = props.disableAutoMarginTop === true ? classes.IgnoreMarginTop : ''


  return (
    <div className={`${classes.BottomButtons} ${ignoreAutoMargin}`}>
      {props.children}
    </div>
  );
};

export default BottomButtons;

