import React from 'react';
import classes from './Input.module.css';
import cx from 'classnames';

export type Props = {
  title: string;
  children: JSX.Element;
  isValid?: boolean;
  className?: string;
};

const Input = (props: Props) => {
  return (
    <div
      className={cx(
        classes.Input,
        { [classes.Invalid]: !props.isValid },
        props.className
      )}
    >
      <div className={classes.InputTitle}>{props.title}</div>
      {props.children}
      <div className={classes.Line} />
    </div>
  );
};

Input.defaultProps = {
  isValid: true,
  className: '',
};

export default Input;
