import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PaymentMethodProviderType,
  ProviderType,
  VerificationType,
} from '../../../../store/types/appState';
import NmiAddCreditCard from './NMIAddCreditCard/NMIAddCreditCard';
import Header from '../../../ui/layout/Header/Header';

export type Props = {
  back: () => void;
  next: (params: PaymentMethodProviderType) => void;
  verificationType: VerificationType;
  providerType: ProviderType;
};

const AddCreditCard = (props: Props) => {
  const [t] = useTranslation();

  const [key, setKey] = useState(0);
  const onError = () => {
    setKey(key + 1);
  };

  let creditCardComponent;
  switch (props.providerType) {
    case ProviderType.NMI:
    case ProviderType.ProPayCNP_NMI:
      creditCardComponent = (
        <NmiAddCreditCard key={key} next={props.next} onError={onError} />
      );
      break;
    default:
      creditCardComponent = null;
      break;
  }

  return (
    <>
      <Header
        title={t('payment.add-credit-card')}
        subTitle={null}
        showBackButton={true}
        back={props.back}
      />
      {creditCardComponent}
    </>
  );
};

export default AddCreditCard;
