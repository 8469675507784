import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useScript from '../../../../../hooks/useScript';
import { setError } from '../../../../../store/actions';
import {
  setNMICCPaymentData,
  setNMICCPaymentToken,
} from '../../../../../store/actions/nmi';
import { setPaymentData } from '../../../../../store/actions/transaction';
import {
  AppState,
  ErrorState,
  MerchantState,
  NMIMerchantConfiguration,
  PaymentMethodNMIProviderType,
  ProviderType,
} from '../../../../../store/types/appState';
import { PortalHideControl } from '../../../../../types/api/apiEnums';
import { nmiConfig } from '../../../../../utils/configs';
import logError from '../../../../../utils/error-logger';
import Input from '../../../../ui/Inputs/Input/Input';
import Loader from '../../../../ui/Loader/Loader';
import classes from './NMIAddCreditCard.module.scss';
import nmiCollectJs from '../../../../../utils/nmi-collectjs'
import BottomButtons from '../../../../ui/layout/BottomButtons/BottomButtons';
import BottomButton from '../../../../ui/layout/BottomButtons/BottomButton';
import Checkbox from '../../../../ui/Inputs/Checkbox/Checkbox';
import { matchCreditCardType } from '../../../../../utils/nmi';

export type Props = {
  next: (params: PaymentMethodNMIProviderType) => void;
  onError: () => void;
};

const NmiAddCreditCard = (props: Props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const merchant = useSelector<AppState, MerchantState>(
    (state) => state.merchant
  );
  const merchantConfiguration = merchant.configuration as NMIMerchantConfiguration;

  const hideControls = useSelector<AppState, PortalHideControl>(
    (state) => state.redirect.hideControls != null ? state.redirect.hideControls : 0
  );

  const isHiddenField = (field: PortalHideControl): boolean => {
    return (hideControls & field) > 0;
  }

  const isSavingPaymentMethodAllowed = useSelector<AppState, boolean>(
    (state) => state.transaction.isSavingPaymentMethodAllowed && !isHiddenField(PortalHideControl.SaveCCBtn)
  );

  const error = useSelector<AppState, ErrorState>(
    (state) => state.error
  )

  const isNMIProPay = useSelector<AppState>((state) => state.merchant.providerType) === ProviderType.ProPayCNP_NMI ? true : false;

  const [isErrorOnThisPage, setIsErrorOnThisPage] = useState(false)

  const nmiErrorHandler = useCallback(() => {
    setIsErrorOnThisPage(true)
    dispatch(setError('error.third-party-script', true))
  }, [dispatch]);

  useEffect(() => {
    if (!error.isError && isErrorOnThisPage)
      props.onError()

  }, [error.isError, isErrorOnThisPage, props])

  const isScriptLoaded = useScript(nmiConfig.collectJs, {
    tokenizationKey: merchantConfiguration.tokenizationKey,
  }, nmiErrorHandler);

  const [isConfigured, setIsConfigured] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(false);

  const next = props.next;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [address, setAddress] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(true);
  const [zipCode, setZipCode] = useState('');
  const [saveCard, setSaveCard] = useState(false);
  const [isValidZipCode, setIsValidZipCode] = useState(true);

  const [isValidCCNumber, setIsValidCCNumber] = useState<boolean | undefined>(
    undefined
  );
  const [isValidCCExp, setIsValidCCExp] = useState<boolean | undefined>(
    undefined
  );
  const [isValidCvv, setIsValidCvv] = useState<boolean | undefined>(undefined);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [collectJsResponse, setCollectJsResponse] = useState<any>(null);

  useEffect(() => {
    if (collectJsResponse) {
      const ccType = matchCreditCardType(collectJsResponse.card.type);
      dispatch(setPaymentData(saveCard));
      dispatch(setNMICCPaymentData(zipCode, address, firstName, lastName));
      dispatch(setNMICCPaymentToken(collectJsResponse.token as string, ccType));
      next({ last4Digits: collectJsResponse.card.number.slice(-4), ccType });
    }
  }, [
    address,
    collectJsResponse,
    dispatch,
    firstName,
    lastName,
    next,
    zipCode,
    saveCard,
  ]);

  const configureNMI = useCallback(() => {
    try {
      nmiCollectJs.configure({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: (response: any) => {
          if (!error.isError) {
            setIsLoadingToken(false);
            setCollectJsResponse(response);
          }
        },
        validationCallback: (param: string, isValid: boolean) => {
          switch (param) {
            case 'ccnumber':
              setIsValidCCNumber(isValid);
              break;
            case 'ccexp':
              setIsValidCCExp(isValid);
              break;
            case 'cvv':
              setIsValidCvv(isValid);
              break;
          }
        },
        variant: 'inline',
        googleFont: 'Lato:400',
        invalidCss: {
          color: '#ff424d;',
        },
        customCss: {
          'border-width': '0',
          'font-family': 'Lato',
          'font-size': '16px',
          'padding-bottom': '1px',
          'padding-top': '1px',
          'height': '22px',
        },
        focusCss: {
          'border-width': '1px',
          'border-color': '#000000',
          'border-style': 'solid',
          'border-radius': '3px',
        },
        fields: {
          cvv: {
            title: 'CVV',
          },
          ccnumber: {
            title: 'Card Number',
          },
          ccexp: {
            title: 'Card Expiration',
          },
        },
        fieldsAvailableCallback: () => {
          setIsConfigured(true);
        },
      }, nmiErrorHandler);
    } catch (error) {
      if (error instanceof Error)
        logError(`Error during NMI CollectJs configuration, original message:${error?.message}`, error?.stack, null, null, error)
      else
        logError(`Error during NMI CollectJs configuration, original message:${String(error)}`, null, null, null, error)
      throw error;
    }

    return () => {
      nmiCollectJs.configure({}, nmiErrorHandler);
    }
  }, [nmiErrorHandler, error.isError])

  const [nmiTimeout, setNMITimeout] = useState(false)


  useEffect(() => {
    if (isScriptLoaded) {
      configureNMI()
    }
  }, [isScriptLoaded, configureNMI]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (nmiTimeout) {
      timeout = setTimeout(() => {
        setNMITimeout(false);
        nmiErrorHandler();
      }, nmiConfig.timeout)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }

    }
  }, [nmiErrorHandler, nmiTimeout])

  const nextHandler = () => {
    if (validate()) {
      setIsLoadingToken(true);
      nmiCollectJs.startPaymentRequest(nmiErrorHandler);
      setNMITimeout(true)
    }
  };

  const validate = (): boolean => {
    let isValid = true;

    if (!isValidCCNumber) {
      setIsValidCCNumber(false);
      isValid = false;
    }

    if (!isValidCCExp) {
      setIsValidCCExp(false);
      isValid = false;
    }

    if (!isValidCvv) {
      setIsValidCvv(false);
      isValid = false;
    }

    if (!isHiddenField(PortalHideControl.FirstName)) {
      isValid =
        handleMinLenghtValidation(firstName, setIsValidFirstName) && isValid;
    }

    if (!isHiddenField(PortalHideControl.LastName)) {
      isValid =
        handleMinLenghtValidation(lastName, setIsValidLastName) && isValid;
    }

    if (!isHiddenField(PortalHideControl.Address)) {
      isValid = handleMinLenghtValidation(address, setIsValidAddress) && isValid;
    }

    if (!isHiddenField(PortalHideControl.ZipCode)) {
      isValid = handleMinLenghtValidation(zipCode, setIsValidZipCode) && isValid;
    }

    return isValid;
  };

  const handleMinLenghtValidation = (
    value: string,
    setValidation: (valid: boolean) => void
  ) => {
    if (value.length <= 0) {
      setValidation(false);
      return false;
    } else setValidation(true);
    return true;
  };

  return (
    <>
      {!error.isError && (!isConfigured || isLoadingToken) ? <Loader /> : null}
      <div>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        <div className={classes.CreditCardInputs}>
          <div className={classes.InputsWrapper}>
            <Input
              title={t('first-name')}
              className={`${classes.NameInput} ${isHiddenField(PortalHideControl.FirstName) ? classes.HiddenField : ''}`}
              isValid={isValidFirstName}
            >
              <input
                id="nmiFirstNameInput"
                type="text"
                value={firstName}
                maxLength={100}
                onChange={(event) => {
                  handleMinLenghtValidation(
                    event.target.value,
                    setIsValidFirstName
                  );
                  setFirstName(event.target.value);
                }}
              />
            </Input>
            <Input
              title={t('last-name')}
              className={`${classes.NameInput} ${isHiddenField(PortalHideControl.LastName) ? classes.HiddenField : ''}`}
              isValid={isValidLastName}
            >
              <input
                id="nmiLastNameInput"
                type="text"
                value={lastName}
                maxLength={100}
                onChange={(event) => {
                  handleMinLenghtValidation(
                    event.target.value,
                    setIsValidLastName
                  );
                  setLastName(event.target.value);
                }}
              />
            </Input>
          </div>

          <Input
            title={t('payment.credit-card-number')}
            isValid={isValidCCNumber}
          >
            <div id="ccnumber" style={{ height: '37px' }} />
          </Input>

          <div className={classes.InputsWrapper}>
            <div className={classes.CcexpInput}>
              <Input title={t('payment.expiration-date')} isValid={isValidCCExp}>
                <div id="ccexp" style={{ height: '37px' }} />
              </Input>
            </div>
            <div className={classes.CcvInput}>
              <Input title={t('payment.cvv')} isValid={isValidCvv}>
                <div id="cvv" style={{ height: '37px' }} />
              </Input>
            </div>

            <div className={`${classes.ZipCodeInput} ${isHiddenField(PortalHideControl.ZipCode) ? classes.HiddenField : ''}`}>
              <Input title={t('payment.zip')} isValid={isValidZipCode}>
                <div style={{ height: '37px' }} >
                  <input
                    id="nmiZipInput"
                    type="text"
                    value={zipCode}
                    maxLength={100}
                    onChange={(event) => {
                      handleMinLenghtValidation(
                        event.target.value,
                        setIsValidZipCode
                      );
                      setZipCode(event.target.value);
                    }}
                  />
                </div>
              </Input>
            </div>
          </div>

          <div className={`${isHiddenField(PortalHideControl.Address) ? classes.HiddenField : ''}`}>
            <Input title={t('address')} isValid={isValidAddress}>
              < input
                id="nmiAddressInput"
                type="text"
                value={address}
                maxLength={isNMIProPay ? 50 : 150}
                onChange={(event) => {
                  handleMinLenghtValidation(
                    event.target.value,
                    setIsValidAddress
                  );
                  setAddress(event.target.value);
                }}
              />
            </Input>
          </div>

          {isSavingPaymentMethodAllowed && (
            <Checkbox
              id="setNMISaveCardCheckbox"
              label={t('payment.save-card-future')}
              isChecked={saveCard}
              onChange={(isChecked) => setSaveCard(isChecked)}
            />
          )}
        </div>
      </div >

      <BottomButtons>
        <BottomButton
          id='addNMICCConfirm'
          className={`ButtonPrimary`}
          onClick={nextHandler}
          disabled={isLoadingToken}
          title={t('payment.confirm')}
          grow={undefined}
        />
      </BottomButtons>
    </>
  );
};

export default NmiAddCreditCard