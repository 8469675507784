import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { setError } from '.';
import ApiMethods from '../../constants/urls';
import axios from '../../utils/axios-tezpay';
import { AppState } from '../types/appState';
import * as actionTypes from './actionTypes';

export const createCheckoutSession = () => {
  return (dispatch: ThunkDispatch<AppState, {}, AnyAction>) => {
    dispatch(createCheckoutSessionStart());

    const url = ApiMethods.Portal.StripeChekoutSession.Base;

    axios
      .post(url)
      .then((response) => {
        dispatch(createCheckoutSessionSuccess(response.data.data.clientSecret));
      })
      .catch((error) => {
        dispatch(createCheckoutSessionFail());
        if (error.response && error.response.status === 401)
          dispatch(setError('error.unauthorized', false));
        else if (error.response && error.response.data.error.errorCode === 2019)
          dispatch(setError('error.transactionAmountTooSmall', false));
        else dispatch(setError('error.api-error', false));
      });
  };
};

export const createCheckoutSessionStart = () => {
  return {
    type: actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_START as typeof actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_START,
  };
};

export const createCheckoutSessionSuccess = (clientSecret: string) => {
  return {
    type: actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_SUCCESS as typeof actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_SUCCESS,
    clientSecret: clientSecret,
  };
};

export const createCheckoutSessionFail = () => {
  return {
    type: actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_FAIL as typeof actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_FAIL,
  };
};

export type Actions =
  | ReturnType<typeof createCheckoutSessionSuccess>
  | ReturnType<typeof createCheckoutSessionStart>
  | ReturnType<typeof createCheckoutSessionFail>;
