import {
  GET_REDIRECT_STATUS_START,
  GET_REDIRECT_STATUS_FAIL,
  GET_REDIRECT_STATUS_SUCCESS,
  SET_REDIRECT_STATUS_TYPE
} from '../actions/actionTypes';
import { RedirectStatusState, RedirectStatus } from '../types/appState';
import { Actions } from '../actions/redirect';

const initialState: RedirectStatusState = {
  status: 0,
  loading: false,
};

const error = (state: RedirectStatusState, redirectStatus: RedirectStatus) => {
  return {
    ...state,
    status: redirectStatus,
  };
};

const setStatus = (
  state: RedirectStatusState,
  status: RedirectStatus
) => {
  return {
    ...state,
    status: status,
    loading: false,
  };
};

const reducer = (
  state: RedirectStatusState = initialState,
  action: Actions
): RedirectStatusState => {
  switch (action.type) {
    case SET_REDIRECT_STATUS_TYPE:
      return error(state, action.status);
    case GET_REDIRECT_STATUS_START:
      return { ...state, loading: true };
    case GET_REDIRECT_STATUS_FAIL:
      return { ...state, loading: false };
    case GET_REDIRECT_STATUS_SUCCESS:
      return setStatus(state, action.status);
    default:
      return state;
  }
};

export default reducer;
