/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from './axios-tezpay';
import ApiMethods from '../constants/urls';

const logError = (
  message: any,
  source: any,
  lineno: any,
  colno: any,
  error: any
) => {
  const object = {
    Message: message,
    StackTrace: error?.stack,
    Source: source,
    LineNo: lineno,
    ColNo: colno,
  };

  axios.post(ApiMethods.Portal.Error, object).catch(() => undefined);
};

export default logError;
