import React from 'react';
import classes from './AddButton.module.css';
import i18n from '../../../../i18n';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';


type AddButtonProps = {
  isSelected: boolean;
  onClick: () => void;
  id: string;
};

const AddButton = ({ onClick, isSelected, id }: AddButtonProps) => {
  const [isMouseMoved, setIsMouseMoved] = React.useState(false);

  const select = () => {
    if (!isMouseMoved && isSelected) {
      onClick();
    }
  };

  React.useEffect(() => {
    setIsMouseMoved(false);
  }, [isSelected]);

  return (
    <div
      id={`${id}Div`}
      className={`${classes.Button} ${isSelected ? classes.Grow : classes.Shrink
        }`}
      onMouseMove={() => {
        if (!isSelected) setIsMouseMoved(true);
      }}
      onClick={select}
    >
      <PlusIcon />
      <label className={classes.Label}>
        {i18n.t('payment.new-payment-method')}
      </label>
    </div>
  );
};

export default AddButton;
