import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as ECheck2Icon } from '../../../assets/e-check_2.svg';
import { AppState } from '../../../store/types/appState';
import {
  CreditCardLabel,
  CreditCardType,
  TenderType,
} from '../../../types/api/apiEnums';
import { creditCardLogoSrc, currencyFormat } from '../../../utils/utils';
import classesLarge from './PaymentDetails-large.module.scss';
import classesSmall from './PaymentDetails-small.module.scss';

export type Props = {
  creditCardType?: CreditCardType;
  name: string;
  last4Digits: string;
  amount: number;
  smallCard: boolean;
};

const PaymentDetails = (props: Props) => {
  const { t, i18n } = useTranslation();
  const tenderType = useSelector(
    (state: AppState) => state.transaction.tenderType
  );

  const classes = props.smallCard ? classesSmall : classesLarge;
  const currencyCode = useSelector<AppState, string | undefined>(
    (state) => state.merchant.configuration?.currencyCode
  );
  const totalPrice = currencyFormat(props.amount, i18n.language, currencyCode);

  return (
    <div className={classes.PaymentDetails}>
      <div>
        {(tenderType === TenderType.CreditCard || tenderType === TenderType.GooglePay || tenderType === TenderType.ApplePay) && props.creditCardType ? (
          <>
            <img
              className={classes.PaymentCardLogo}
              src={creditCardLogoSrc(props.creditCardType)}
              alt={props.creditCardType.toString()}
            />
            <div className={classes.PaymentCardText}>
              {CreditCardLabel.get(props.creditCardType)}
              <b>*{props.last4Digits}</b>
            </div>
          </>
        ) : (
          <>
            <ECheck2Icon className={classes.PaymentCardLogo} />
            <div className={classes.PaymentCardECheckText}>
              <b>*{props.last4Digits}</b>
              {props.name}
            </div>
          </>
        )}
      </div>

      <div className={classes.Ellipse}>
        <div className={classes.PaymentTotal}>
          {props.smallCard ? (
            <>
              <div className={classes.PaymentLine} />
              <h1 className={classes.PaymentTotalText}>
                {t('payment.total')}:
              </h1>
            </>
          ) : (
            <>
              <h1 className={classes.PaymentTotalText}>
                {t('payment.total')}:
              </h1>
              <div className={classes.PaymentLine} />
            </>
          )}

          <div className={classes.PaymentTotalPrice}>{totalPrice}</div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
