import React from 'react';
import { CreditCardType } from '../../../types/api/apiEnums';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import classes from './PaymentSuccess.module.css';
import bottomButtonsClasses from '../../ui/layout/BottomButtons/BottomButtons.module.scss'
import { useTranslation } from 'react-i18next';
import BottomButton from '../../ui/layout/BottomButtons/BottomButton';
import BottomButtons from '../../ui/layout/BottomButtons/BottomButtons';

export type Props = {
  name: string;
  creditCardType: CreditCardType;
  last4Digits: string;
  amount: number;
  ok: () => void;
};

const PaymentStatus = (props: Props) => {
  const [t] = useTranslation();
  return (
    <>
      <div className={classes.Header}>
        <div className={classes.Checkmark} />
        <div className={classes.Success}>
          <h2 className={classes.SuccessHeader}>{t('success')}</h2>
          <div className={classes.SuccessText}>{t('payment.success')}</div>
        </div>
      </div>
      <PaymentDetails
        name={props.name}
        amount={props.amount}
        creditCardType={props.creditCardType}
        last4Digits={props.last4Digits}
        smallCard={true}
      />
      <BottomButtons>
        <BottomButton
          id="paymentSuccessOk"
          className={`${bottomButtonsClasses.ButtonShort} ButtonPrimary`}
          onClick={props.ok}
          title={t('Ok')}
          disabled={undefined}
          grow={undefined}
        />
      </BottomButtons>
    </>
  );
};

export default PaymentStatus;
