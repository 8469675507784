import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PayCard,
  SelectedPayCard,
} from '../../../../store/types/appState';
import { TenderType } from '../../../../types/api/apiEnums';
import PayCardSlider from '../PayCardSlider/PayCardSlider';
import DeletePaymentMethod from '../DeletePaymentMethod/DeletePaymentMethod';
import Header from '../../../ui/layout/Header/Header';
import BottomButton from '../../../ui/layout/BottomButtons/BottomButton';
import BottomButtons from '../../../ui/layout/BottomButtons/BottomButtons';
import bottomButtonsClasses from '../../../ui/layout/BottomButtons/BottomButtons.module.scss'

export type Props = {
  payCards: PayCard[];
  confirm: (data: SelectedPayCard) => void;
  add: (tenderType: TenderType) => void;
  delete: (PayCard: PayCard) => void;
  back: () => void;
};

const PayCardsList = (props: Props) => {
  const [t] = useTranslation();

  const [selectedCard, setSelectedCard] = useState<PayCard | null>(null);
  const [isPaymentMethodDeleting, setIsPaymentMethodDeleting] = useState<
    boolean
  >(false);

  const showDeleteHandler = () => {
    setIsPaymentMethodDeleting(true);
  };

  const confirmEC = (
    eCheckId: string,
    last4Digits: string,
    routingNumberLast4Digits: string
  ) => {
    props.confirm({
      type: TenderType.ECheck,
      last4Digits,
      routingNumberLast4Digits,
      eCheckId,
    });
  };

  const selectCardHandler = (selectedCard: PayCard) => {
    setSelectedCard(selectedCard);

    if (selectedCard.type === TenderType.ECheck) {
      confirmEC(
        selectedCard.id,
        selectedCard.last4Digits,
        selectedCard.routingNumberLast4Digits
      );
    }
  };

  return (
    <>
      <>
        {isPaymentMethodDeleting && (
          <DeletePaymentMethod
            delete={props.delete}
            selectedCard={selectedCard}
            cancel={() => setIsPaymentMethodDeleting(false)}
          />
        )}
      </>
      <Header
        title={t('payment.e-check')}
        subTitle={t('payment.choose-e-check')}
        showBackButton={true}
        back={props.back}
      />
      {props.payCards ? (
        <PayCardSlider
          payCards={props.payCards}
          add={() => props.add(TenderType.ECheck)}
          selectedCardClicked={selectCardHandler}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
        />
      ) : null}
      {selectedCard && (
        <BottomButtons disableAutoMarginTop={true}>
          <BottomButton
            id="deleteECheck"
            className={`${bottomButtonsClasses.ButtonShort} ButtonOutlined`}
            onClick={showDeleteHandler}
            title={t('delete')}
            disabled={undefined}
            grow={undefined}
          />
          <BottomButton
            id="payECheck"
            className={`ButtonPrimary`}
            onClick={() => selectCardHandler(selectedCard)}
            title={t('pay')}
            disabled={undefined}
            grow={undefined}
          />
        </BottomButtons>
      )}
    </>
  );
};

export default PayCardsList;
