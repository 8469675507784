export enum CreditCardType {
  Visa = 1,
  MasterCard = 2,
  AmericanExpress = 3,
  DinersClub = 4,
  Discover = 5,
  JCB = 6,
  Invalid = 7,
}

export const CreditCardLabel = new Map<CreditCardType, string>([
  [CreditCardType.Visa, 'Visa'],
  [CreditCardType.MasterCard, 'MasterCard'],
  [CreditCardType.AmericanExpress, 'American Express'],
  [CreditCardType.DinersClub, 'DinersClub'],
  [CreditCardType.Discover, 'Discover'],
  [CreditCardType.JCB, 'JCB'],
  [CreditCardType.Invalid, ''],
]);

export enum TenderType {
  CreditCard = 1,
  ECheck = 2,
  GooglePay = 3,
  ApplePay = 4,
}

export enum ApiTransactionStatus {
  Approved = 1,
  Declined = 2,
  UserTimeout = 3,
}

export enum ApiActionStatus {
  Approved = 1,
  Declined = 2,
}

export enum PortalHideControl {
  None = 0,
  ZipCode = 1,
  Address = 2,
  SaveCCBtn = 4,
  FirstName = 8,
  LastName = 16,
}
