import { ApiTransactionStatus, CreditCardType } from '../types/api/apiEnums';
import amexLogo from '../assets/credit-cards/amex.svg';
import dinnersClubLogo from '../assets/credit-cards/diners-club.svg';
import discoverLogo from '../assets/credit-cards/discover.svg';
import jcbLogo from '../assets/credit-cards/jcb.svg';
import mastercardLogo from '../assets/credit-cards/mastercard.svg';
import unknownLogo from '../assets/credit-cards/unknown.svg';
import visaLogo from '../assets/credit-cards/visa.svg';
import smallAmexLogo from '../assets/credit-cards/small/amex.svg';
import smallDinnersClubLogo from '../assets/credit-cards/small/diners-club.svg';
import smallDiscoverLogo from '../assets/credit-cards/small/discover.svg';
import smallJcbLogo from '../assets/credit-cards/small/jcb.svg';
import smallMastercardLogo from '../assets/credit-cards/small/mastercard.svg';
import smallUnknownLogo from '../assets/credit-cards/small/unknown.svg';
import smallVisaLogo from '../assets/credit-cards/small/visa.svg';

import { CardBrand } from 'credit-card-type';
import { RedirectStatus, TransactionStatus } from '../store/types/appState';

export const currencyFormat = (
  amount: number,
  language: string,
  currencyCode: string | undefined
) => {
  return Intl.NumberFormat(language, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount / 100);
};

export const concatNull = (...strings: (string | null)[]): string | null => {
  if (strings.every((v) => (v == null ? true : false))) return null;

  return strings.map((v) => (v == null ? '' : v)).join(' ');
};

export const creditCardLogoSrc = (creditCardType: CreditCardType) => {
  switch (creditCardType) {
    case CreditCardType.AmericanExpress:
      return amexLogo;
    case CreditCardType.Visa:
      return visaLogo;
    case CreditCardType.MasterCard:
      return mastercardLogo;
    case CreditCardType.DinersClub:
      return dinnersClubLogo;
    case CreditCardType.Discover:
      return discoverLogo;
    case CreditCardType.JCB:
      return jcbLogo;
    case CreditCardType.Invalid:
    default:
      return unknownLogo;
  }
};

export const creditCardSmallLogoSrc = (creditCardType: CreditCardType) => {
  switch (creditCardType) {
    case CreditCardType.AmericanExpress:
      return smallAmexLogo;
    case CreditCardType.Visa:
      return smallVisaLogo;
    case CreditCardType.MasterCard:
      return smallMastercardLogo;
    case CreditCardType.DinersClub:
      return smallDinnersClubLogo;
    case CreditCardType.Discover:
      return smallDiscoverLogo;
    case CreditCardType.JCB:
      return smallJcbLogo;
    case CreditCardType.Invalid:
    default:
      return smallUnknownLogo;
  }
};

export const creditCardType = (
  creditCardType: CardBrand | undefined
): CreditCardType => {
  switch (creditCardType) {
    case 'american-express':
      return CreditCardType.AmericanExpress;
    case 'visa':
      return CreditCardType.Visa;
    case 'mastercard':
      return CreditCardType.MasterCard;
    case 'diners-club':
      return CreditCardType.DinersClub;
    case 'discover':
      return CreditCardType.Discover;
    case 'jcb':
      return CreditCardType.JCB;
    default:
      return CreditCardType.Invalid;
  }
};

export const transactionStatus = (
  apiTransactionStatus: ApiTransactionStatus
): TransactionStatus => {
  switch (apiTransactionStatus) {
    case ApiTransactionStatus.Approved:
      return TransactionStatus.Accepted;
    case ApiTransactionStatus.Declined:
      return TransactionStatus.Declined;
    case ApiTransactionStatus.UserTimeout:
      return TransactionStatus.UserTimeout;
    default:
      console.error('transactionStatus is not known');
      return TransactionStatus.Unknown;
  }
};

export const matchCreditCardType = (
  type: string | undefined
): CreditCardType => {
  switch (type?.toUpperCase()) {
    case 'VISA':
      return CreditCardType.Visa;
    case 'AMEX':
      return CreditCardType.AmericanExpress;
    case 'DISCOVER':
      return CreditCardType.Discover;
    case 'JCB':
      return CreditCardType.JCB;
    case 'MASTERCARD':
      return CreditCardType.MasterCard;
    default:
      return CreditCardType.Invalid;
  }
};

export const addLoadingAction = (loadingActions: Array<string>, loadingAction: string): { loadingActions: Array<string>, loading: boolean } => {
  const newLoadingActions: Array<string> = [...loadingActions, loadingAction]

  return {
    loadingActions: newLoadingActions,
    loading: newLoadingActions?.length > 0,
  }
}

export const removeLoadingAction = (loadingActions: Array<string>, loadingAction: string): { loadingActions: Array<string>, loading: boolean } => {
  const newLoadingActions: Array<string> = [...loadingActions]
  const index = loadingActions.indexOf(loadingAction);

  if (index > -1)
    newLoadingActions.splice(index, 1)

  return {
    loadingActions: newLoadingActions,
    loading: newLoadingActions?.length > 0,
  }
}

export const isFinalStatus = (redirectStatus: RedirectStatus) => {
  switch (redirectStatus) {

    case RedirectStatus.Succeeded:
    case RedirectStatus.Failed:
    case RedirectStatus.CallbackFailed:
      return true;
    case RedirectStatus.None:
    case RedirectStatus.BeforePayment:
    case RedirectStatus.DuringPayment:
    default:
      return false;
  }
}