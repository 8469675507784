import React from 'react';
import { CreditCardType } from '../../../types/api/apiEnums';
import classes from './PaymentDeclined.module.css';
import { useTranslation } from 'react-i18next';
import BottomButton from '../../ui/layout/BottomButtons/BottomButton';
import BottomButtons from '../../ui/layout/BottomButtons/BottomButtons';
import bottomButtonsClasses from '../../ui/layout/BottomButtons/BottomButtons.module.scss'

export type Props = {
  creditCardType: CreditCardType;
  last4Digits: string;
  amount: number;
  cancel: () => void;
  tryAgain: () => void;
};

const PaymentDecline = (props: Props) => {
  const [t] = useTranslation();
  return (
    <>
      <div className={classes.Ellipse}>
        <div className={classes.Header}>
          <div className={classes.X} />
          <div className={classes.Declined}>
            <h2 className={classes.DeclinedHeader}>{t('declined')}</h2>
            <div className={classes.DeclinedText}>{t('payment.declined')}</div>
          </div>
        </div>
        <BottomButtons>
          <BottomButton
            id="paymentDeclineClose"
            className={`${bottomButtonsClasses.ButtonShort} ButtonOutlined`}
            onClick={props.cancel}
            title={t('close')}
            disabled={undefined}
            grow={undefined}
          />
        </BottomButtons>
      </div>
    </>
  );
};

export default PaymentDecline;
