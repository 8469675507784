import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import AutoPayment from './containers/Payment/AutoPayment';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './components/ApplicationInsights/AppInsights';
import OneTimePayment from './containers/Payment/OneTimePayment';
import AfterPayment from './containers/Payment/AfterPayment';

const App = () => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Switch>
        <Route path="/:token" exact component={OneTimePayment} />
        <Route path="/:token/return" exact component={AfterPayment} />
        <Route path="/auto-payment/:token" exact component={AutoPayment} />
        <Route component={() => <div>Not Found</div>} />
      </Switch>
    </AppInsightsContext.Provider>
  );
};

export default App;
