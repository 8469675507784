import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ECheckInfoIcon } from '../../../../../assets/e-check-info.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/info.svg';
import useScript from '../../../../../hooks/useScript';
import { setError } from '../../../../../store/actions';
import { setNMIECheckPaymentToken } from '../../../../../store/actions/nmi';
import { setPaymentData } from '../../../../../store/actions/transaction';
import {
  AppState,
  MerchantState,
  NMIMerchantConfiguration,
  RedirectType,
} from '../../../../../store/types/appState';
import { nmiConfig } from '../../../../../utils/configs';
import Input from '../../../../ui/Inputs/Input/Input';
import Loader from '../../../../ui/Loader/Loader';
import classes from './NMIAddECheck.module.scss';
import nmiCollectJs from '../../../../../utils/nmi-collectjs'
import BottomButton from '../../../../ui/layout/BottomButtons/BottomButton';
import BottomButtons from '../../../../ui/layout/BottomButtons/BottomButtons';
import Checkbox from '../../../../ui/Inputs/Checkbox/Checkbox';

export type Props = {
  next: (name: string, last4Digits: string) => void;
};

const NmiAddECheck = (props: Props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const merchant = useSelector<AppState, MerchantState>(
    (state) => state.merchant
  );
  const merchantConfiguration = merchant.configuration as NMIMerchantConfiguration;

  const isSavingPaymentMethodAllowed = useSelector<AppState, boolean>(
    (state) => state.transaction.isSavingPaymentMethodAllowed
  );

  const redirectType = useSelector<AppState, RedirectType>(
    (state) => state.redirect.redirectType
  );

  const [isError, setIsError] = useState(false);

  const nmiErrorHandler = useCallback(() => {
    setIsError(true)
    dispatch(setError('error.third-party-script', false))
  }, [dispatch]);

  const isScriptLoaded = useScript(nmiConfig.collectJs, {
    tokenizationKey: merchantConfiguration.tokenizationKey,
  }, nmiErrorHandler);

  const [isConfigured, setIsConfigured] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(false);

  const next = props.next;

  const [isValidCheckName, setIsValidCheckName] = useState(true);

  const [isValidAccountNumber, setIsValidAccountNumber] = useState<
    boolean | undefined
  >(undefined);
  const [isValidRoutingNumber, setIsValidRoutingNumber] = useState<
    boolean | undefined
  >(undefined);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [collectJsResponse, setCollectJsResponse] = useState<any>(null);

  const [saveECheck, setSaveECheck] = useState(false);

  useEffect(() => {
    if (collectJsResponse) {
      dispatch(
        setPaymentData(
          (isSavingPaymentMethodAllowed &&
            (redirectType === RedirectType.OneTimePayment ||
              redirectType === RedirectType.ManagePaymentMethods)) ||
          (saveECheck && redirectType === RedirectType.AutoPayment)
        )
      );
      dispatch(
        setNMIECheckPaymentToken(
          collectJsResponse.token as string,
          collectJsResponse.check.name
        )
      );
      next(
        collectJsResponse.check.name,
        collectJsResponse.check.account.slice(-4)
      );
    }
  }, [
    collectJsResponse,
    dispatch,
    next,
    isSavingPaymentMethodAllowed,
    saveECheck,
    redirectType,
  ]);

  useEffect(() => {
    if (isScriptLoaded) {
      nmiCollectJs.configure({
        callback: (response: any) => {
          setIsLoadingToken(false);
          setCollectJsResponse(response);
        },
        validationCallback: (param: string, isValid: boolean) => {
          switch (param) {
            case 'checkname':
              setIsValidCheckName(isValid);
              break;
            case 'checkaccount':
              setIsValidAccountNumber(isValid);
              break;
            case 'checkaba':
              setIsValidRoutingNumber(isValid);
              break;
          }
        },
        variant: 'inline',
        googleFont: 'Lato:400',
        invalidCss: {
          color: '#ff424d;',
        },
        customCss: {
          'border-width': '0',
          'font-family': 'Lato',
          'font-size': '16px',
          'padding-bottom': '1px',
          'padding-top': '1px',
          height: '19px',
        },
        focusCss: {
          'border-width': '1.2px',
          'border-color': '#000000',
          'border-style': 'solid',
          'border-radius': '3px',
        },
        fields: {
          checkaccount: {
            title: 'Account number',
          },
          checkaba: {
            title: 'Routing number',
          },
          checkname: {
            title: 'Name',
          },
        },
        fieldsAvailableCallback: () => {
          setIsConfigured(true);
        },
      }, nmiErrorHandler);
    }
  }, [isScriptLoaded, dispatch, next, nmiErrorHandler]);

  const nextHandler = () => {
    const isValid = validate();
    if (isValid) {
      setIsLoadingToken(true);
      nmiCollectJs.startPaymentRequest(nmiErrorHandler);
    }
  };

  const validate = (): boolean => {
    let isValid = true;

    if (!isValidAccountNumber) {
      setIsValidAccountNumber(false);
      isValid = false;
    }

    if (!isValidRoutingNumber) {
      setIsValidRoutingNumber(false);
      isValid = false;
    }

    if (!isValidCheckName) {
      setIsValidCheckName(false);
      isValid = false;
    }

    return isValid;
  };

  return (
    <>
      {!isError && (!isConfigured || isLoadingToken) ? <Loader /> : null}
      <div>
        <div className={classes.ECheckInfoIconWrapper}>
          <ECheckInfoIcon className={classes.ECheckInfoIcon} />
        </div>

        <div className={classes.ECheckInputs}>
          <div className={classes.InputsWrapper}>
            <Input title={t('name')} isValid={isValidCheckName}>
              <div id="checkname" style={{ height: '37px' }} />
            </Input>
          </div>

          <div className={classes.InputsWrapper}>
            <Input
              title={t('payment.account-number')}
              isValid={isValidAccountNumber}
            >
              <div id="checkaccount" style={{ height: '37px' }} />
            </Input>
            <Input
              title={t('payment.routing-number')}
              isValid={isValidRoutingNumber}
            >
              <div id="checkaba" style={{ height: '37px' }} />
            </Input>
          </div>
        </div>
      </div>

      {redirectType === RedirectType.AutoPayment ? (
        <>
          <Checkbox
            id="setNMISaveEcheckCheckbox"
            label={t('payment.authorize-recurring-payment')}
            isChecked={saveECheck}
            onChange={(isChecked) => setSaveECheck(isChecked)}
          />
          <div className={classes.ECheckAuthReccuringPayment}>
            <div className={classes.ECheckAuthReccuringPaymentDesc}>
              {t('payment.authorize-recurring-payment-desc')}
            </div>
          </div>
        </>
      ) : isSavingPaymentMethodAllowed ? (
        <div className={classes.InfoWrapper}>
          <InfoIcon />
          <span>{t('payment.echeck-saved-for-future')}</span>
        </div>
      ) : (
        ''
      )}

      <BottomButtons>
        <BottomButton
          id='addNMIeCheckConfirm'
          className={`ButtonPrimary`}
          onClick={nextHandler}
          disabled={isLoadingToken}
          title={t('payment.confirm')}
          grow={undefined}
        />
      </BottomButtons>
    </>
  );
};

export default NmiAddECheck;
