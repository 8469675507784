import * as actionTypes from '../actions/actionTypes';
import { Actions } from '../actions/userTimer';
import { UserTimerState } from '../types/appState';

const initialState: UserTimerState = {
  userTimerExpireDateUtc: '',
  serverCurrentDateUtc: '',
  isUserTimerExpired: false,
  userTimerDuration: 0,
};

export const setExpirationUserDate = (
  state: UserTimerState,
  newState: UserTimerState
) => {
  return {
    ...state,
    ...newState,
  };
};

export const setIsUserTimerExpired = (
  state: UserTimerState,
  isUserTimerExpired: boolean
) => {
  return {
    ...state,
    isUserTimerExpired,
  };
};

export const setUserTimerDuration = (
  state: UserTimerState,
  userTimerDuration: number
) => {
  return {
    ...state,
    userTimerDuration,
  };
};

export const reducer = (
  state: UserTimerState = initialState,
  action: Actions
): UserTimerState => {
  switch (action.type) {
    case actionTypes.START_USER_TIMER_SUCCESS:
      return setExpirationUserDate(state, action.data);
    case actionTypes.SET_USER_TIMER_EXPIRED:
      return setIsUserTimerExpired(state, action.isUserTimerExpired);
    case actionTypes.SET_USER_TIMER_DURATION:
      return setUserTimerDuration(state, action.userTimerDuration);
    default:
      return state;
  }
};

export default reducer;
