import * as actionTypes from '../actions/actionTypes';
import { Actions } from '../actions/stripe';
import { StripeState } from '../types/appState';

const initialState: StripeState = {
  checkoutSessionClientSecret: '',
  loading: false
};

const createCheckoutSessionFail = (state: StripeState) => {
  return {
    ...state,
    loading: false
  }
}

const createCheckoutSessionStart = (state: StripeState) => {
  return {
    ...state,
    loading: true
  }
}

const createCheckoutSessionSuccess = (state: StripeState, clientSecret: string) => {
  return {
    ...state,
    loading: false,
    checkoutSessionClientSecret: clientSecret
  }
}

const reducer = (
  state: StripeState = initialState,
  action: Actions
): StripeState => {
  switch (action.type) {
    case actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_START:
      return createCheckoutSessionStart(state);
    case actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_FAIL:
      return createCheckoutSessionFail(state);
    case actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_SUCCESS:
      return createCheckoutSessionSuccess(state, action.clientSecret);
    default:
      return state;
  }
};

export default reducer;
