import React, { useRef, useEffect } from 'react';
import Input from '../Input/Input';
import classes from './NumberInput.module.css';

export type Props = {
  title: string;
  maxLength: number;
  value: string;
  valueChange: (value: string) => void;
  focus: boolean;
  isValid: boolean;
};

const NumberInput = (props: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  const focus = props.focus;
  useEffect(() => {
    if (ref.current && focus) {
      ref.current.focus();
    }
  }, [focus]);

  return (
    <Input title={props.title} isValid={props.isValid}>
      <input
        className={classes.Input}
        ref={ref}
        type="tel"
        value={props.value}
        onChange={(event) =>
          props.valueChange(
            event.target.value.substring(0, props.maxLength).replace(/\D/g, '')
          )
        }
      />
    </Input>
  );
};

export default NumberInput;
