import axios from '../../utils/axios-tezpay';
import { AnyAction } from 'redux';
import ApiMethods from '../../constants/urls';
import { PortalHideControl } from '../../types/api/apiEnums';
import { RedirectState, RedirectStatus, RedirectType } from '../types/appState';
import * as actionTypes from './actionTypes';
import { setError } from './error';
import { ThunkDispatch } from 'redux-thunk';
import { getTransactionFail, getTransactionStart, getTransactionSuccess } from './transaction';

export const setRedirectType = (redirectType: RedirectType) => {
  return {
    type: actionTypes.SET_REDIRECT_TYPE as typeof actionTypes.SET_REDIRECT_TYPE,
    redirectType: redirectType,
  };
};

export const setRedirectStatus = (status: RedirectStatus) => {
  return {
    type: actionTypes.SET_REDIRECT_STATUS_TYPE as typeof actionTypes.SET_REDIRECT_STATUS_TYPE,
    status: status,
  };
};

export const getCustomSettingsStart = () => {
  return {
    type: actionTypes.GET_REDIRECT_SETTINGS_START as typeof actionTypes.GET_REDIRECT_SETTINGS_START,
  };
};

export const getCustomSettingsFail = () => {
  return {
    type: actionTypes.GET_REDIRECT_SETTINGS_FAIL as typeof actionTypes.GET_REDIRECT_SETTINGS_FAIL,
  };
};

export const getCustomSettingsSuccess = (
  hideControls: PortalHideControl | null
) => {
  return {
    type: actionTypes.GET_REDIRECT_SETTINGS_SUCCESS as typeof actionTypes.GET_REDIRECT_SETTINGS_SUCCESS,
    hideControls: hideControls,
  };
};

export const getRedirectStatusStart = () => {
  return {
    type: actionTypes.GET_REDIRECT_STATUS_START as typeof actionTypes.GET_REDIRECT_STATUS_START,
  };
};

export const getRedirectStatusFail = () => {
  return {
    type: actionTypes.GET_REDIRECT_STATUS_FAIL as typeof actionTypes.GET_REDIRECT_STATUS_FAIL,
  };
};

export const getRedirectStatusSuccess = (
  status: RedirectStatus
) => {
  return {
    type: actionTypes.GET_REDIRECT_STATUS_SUCCESS as typeof actionTypes.GET_REDIRECT_STATUS_SUCCESS,
    status: status,
  };
};

export const getRedirectSettings = () => {
  return (dispatch: ThunkDispatch<RedirectState, {}, AnyAction>) => {
    dispatch(getCustomSettingsStart());
    axios
      .get(ApiMethods.Portal.RedirectCustomSettings)
      .then((response) => {
        const data = response.data.data;
        dispatch(getCustomSettingsSuccess(data.hideControls));
      })
      .catch((error) => {
        console.error(error);
        dispatch(getCustomSettingsFail());
        if (error.response && error.response.status === 401)
          dispatch(setError('error.unauthorized', false));
        else dispatch(setError('error.api-error', false));
      });
  };
};


export const getRedirectData = (displayError: boolean = true) => {
  return (dispatch: ThunkDispatch<RedirectState, {}, AnyAction>) => {
    dispatch(getRedirectStatusStart());
    dispatch(getTransactionStart());
    axios
      .get(ApiMethods.Portal.RedirectData)
      .then((response) => {
        const data = response.data.data;
        dispatch(getRedirectStatusSuccess(data.status));
        dispatch(getTransactionSuccess(data.amount, data.transactionStatus, data.redirectUrl, data.last4Digits, data.cardType, data.tenderType));
      })
      .catch((error) => {
        console.error(error);
        dispatch(getRedirectStatusFail());
        dispatch(getTransactionFail());
        if (displayError) {
          if (error.response && error.response.status === 401)
            dispatch(setError('error.unauthorized', false));
          else dispatch(setError('error.api-error', false));
        }
      });
  };
};

export type Actions =
  | ReturnType<typeof setRedirectType>
  | ReturnType<typeof setRedirectStatus>
  | ReturnType<typeof getCustomSettingsStart>
  | ReturnType<typeof getCustomSettingsFail>
  | ReturnType<typeof getCustomSettingsSuccess>
  | ReturnType<typeof getRedirectStatusStart>
  | ReturnType<typeof getRedirectStatusFail>
  | ReturnType<typeof getRedirectStatusSuccess>;